<template>
    <div class="carousel">
        <div class="carousel-item">
            <img :src="slides[0].image" :alt="slides[0].alt">
        </div>
        <div class="carousel-indicators">
            <RelatedLogo/>
        </div>
    </div>
</template>

<script>
import RelatedLogo from './RelatedLogo.vue';

export default {
    name: 'PortfolioPage',
    components: {
        RelatedLogo
    },
    data() {
        return {
            currentSlide: 0,
            slides: [
                { image: require('@/assets/images/portfolio/Alexey_Pilipenko_0395.jpg'), alt: 'image 1' },
            ],
        }
    },
    mounted() {
        window.addEventListener('wheel', this.handleScroll);
    },
    beforeUnmount() {
      window.removeEventListener('wheel', this.handleScroll);
    },
    methods: {

    }
};

</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body,
html {
  height: 100%;
  overflow: hidden;
}

.carousel {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  letter-spacing: 5px;
}

.carousel-item {
  min-width: 100%;
  height: 100vh;
  position: relative;
  padding-bottom: 300px;
}

.carousel-item img {
  margin-top: 80px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.carousel-indicators {
  width: 100%;
  height: 300px;
  left: 0;
  position: absolute;
  bottom: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--dark-color-background);
  padding: 20px 0;
}

.trust {
  text-align: center;
  /* color: var(--dark-color-font); */
  margin-bottom: 10px;
  margin-top: 20px;
}

.carousel-indicators p {
  font-size: 14px;
  margin: 0;
}

@media (max-height: 1000px) {
  .carousel-indicators {
    height: 230px;
    padding: 0;
  }
  .carousel-indicators p {
    margin-top: 10px;
    font-size: 12px;
  }
}


@media (max-width: 822px) {
  .carousel-indicators {
    height: 230px;
    padding: 0;
  }
  .carousel-indicators p {
    margin-top: 10px;
    font-size: 12px;
  }

}
</style>