<!-- src/components/CartNotification.vue -->
<template>
  <div v-if="show" class="notification">
    <p>{{ message }}</p>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      required: true
    },
    duration: {
      type: Number,
      default: 3000
    }
  },
  data() {
    return {
      show: true
    };
  },
  mounted() {
    setTimeout(() => {
      this.show = false;
    }, this.duration);
  }
};
</script>

<style scoped>
.notification {
  position: fixed;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--dark-color-background);
  color: var(--dark-color-font);
  padding: 15px 20px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  font-size: 16px;
  z-index: 1000;
  text-align: center;
  max-width: 80%; /* Opcjonalnie: ogranicz szerokość na mniejszych ekranach */
}
</style>
