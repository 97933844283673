<template>
    <footer class="app-footer">
        <div class="horizontal-line"></div>
        <div class="footer-content">
            <div class="column">
                <h3>{{ translations.title_info }}</h3>
                <ul>
                    <li><a href="/contact">{{ translations.contact }}</a></li>
                    <li><a href="/policy">{{ translations.policy }}</a></li>
                    <li><a href="/regulation">{{ translations.regulation }}</a></li>
                    <li><a href="/regulation-rent">{{ translations.regulation_rent }}</a></li>
                </ul>
            </div>
            <div class="vertical-line"></div>
            <div class="column">
                <div class="social-icons">
                    <a href="https://www.facebook.com/profile.php?id=61559974028659" target="_blank">
                        <img :src="logofb" alt="Logo facebook" />
                    </a>
                    <a href="https://www.instagram.com/cocktailservice.pl/" target="_blank">
                        <img :src="logoinst" alt="Logo instagram" />
                    </a>
                </div>
                <a href="mailto:biuro@cocktailservice.pl" class="email">biuro@cocktailservice.pl</a>
            </div>
            <div class="vertical-line"></div>
            <div class="column">
                <h3 class="address">{{ translations.title_address }}</h3>
                <p class="adress">
                    COCKTAIL SERVICE SP. Z O.O.<br>
                    Jagiellońska 82C Bud. 109<br>
                    03-301, Warszawa
                </p>
                <p class="nip">NIP: 5252996326</p>
            </div>
        </div>
        <div class="horizontal-line"></div>
        <p class="copy">COPYRIGHT BY COCKTAIL SERVICE</p>
    </footer>
</template>

<script>


import { computed } from 'vue';
import translationsData from '@/assets/text_lang/translations.json';
import { useLanguageStore } from '@/theme'; 

export default {
    name: 'AppFooter',
    data() {
        return {
            logofb: require('@/assets/images/contact/fb.png'),
            logoinst: require('@/assets/images/contact/instagram.png'),
        };
    },
    setup() {
    const languageStore = useLanguageStore();
      
      const translations = computed(() => {
        return translationsData[languageStore.currentLanguage]["footer"];
      });
      return {
        currentLanguage: languageStore.currentLanguage,
        translations,
      };
  },
};
</script>

<style scoped>
.app-footer {
    background-color: var(--dark-color-font);
    color: var(--dark-color-background);
    text-align: center;
    padding: 20px 0;
    width: 100%;
    height: 100%;
    left: 0;
    z-index: 1000;
    /* opacity: 1; */
    /* position: relative; */
}

.footer-content {
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 50px;
    height: 170px;
    font-size: 12px;

}

.column {
    flex: 1;
    padding: 0 20px;
    text-align: left;
    height: 100%;
    
}

.column h3 {
    /* font-size: 16px;
    font-weight: bold; */
    height: 30%;
    margin-bottom: 10px;
}

.column ul {
    display: flex;
    flex-direction: column-reverse;
    height: 50%; /* lub inna wartość, w zależności od efektu */
    list-style: none;
    padding: 0;
    margin: 0;
}
.column ul li a {
    color: var(--dark-color-background);
    text-decoration: none;
}

.column ul li {
    margin-bottom: 5px;
}

.column p {
    margin: 10px 0;
    text-decoration: none;
}



.social-icons {
    display: flex;
    height: 30%;
    /* justify-content: space-around; */
    margin-top: 10px;
    margin-bottom: 10px;
}

.social-icons a img {
    width: 45px;
    height: 45px;
}

.column .email {
    margin-bottom: 20px;
    height: 50%;
    display: flex;
    flex-direction: column-reverse;
    text-decoration: none;
    color: var(--dark-color-background);
}

.column .address {
    height: 15%;
}
.column .adress {
    height: 35%;
}


.column .nip {
    display: flex;
    flex-direction: column-reverse;
    height: 20%; /* lub inna wartość, w zależności od efektu */
    list-style: none;
    padding: 0;
    margin: 0;
}

.horizontal-line {
    width: calc(100% - 100px);
    height: 1px;
    background-color: var(--dark-color-background);
    margin: 20px auto;
}

.vertical-line {
    width: 1px;
    height: auto;
    /* Dostosowanie wysokości do rodzica */
    background-color: var(--dark-color-background);
    padding: 0;
    /* margin: 40px 0px 30px 0px; */
    /* Upewnienie się, że nie ma marginesów */
}

.copy {
    font-size: 11px;
}

/* Dla urządzeń mobilnych */
@media (max-width: 700px) {
    .copy {
        font-size: 8px;
    }


    .horizontal-line {
        width: 100%;
        height: 1px;
        margin: 10px auto;
    }

    .vertical-line {
        width: 100%;
        height: 1px;
        margin: 10px auto;
    }

    .footer-content {
        font-size: 10px;
        padding: 5px;
        height: 100%;
    }

    .footer-content {
        flex-direction: column;
        align-items: center;
        padding: 20px;
    }

    .column {
        width: 90%;
        margin: 15px 0;
        align-items: center;
    }

    .column h3 {
        margin-bottom: 30px;
    }

    .footer-nav {
        margin: 20px 0;
    }
    .social-icons {
        margin-bottom: 20px;
    }
    .column .adress {
        margin-bottom: 15px;
    }
}
</style>