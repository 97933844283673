<template>
    <div id="app" class="shop">
        <div class="header">
            <p class="selected-category">{{ translations.title }}</p>
            <span>{{ translations.description }}</span>
        </div>
        <div class="cart">
            <div class="cart-header">
                <span class="name">{{ translations.table_cart.name }}</span>
                <span>{{ translations.table_cart.quantity }}</span>
                <span>{{ translations.table_cart.days }}</span>
                <span>{{ translations.table_cart.sum_net }}</span>
                <span>{{ translations.table_cart.sum_gross }}</span>
            </div>
            <div class="cart-item" v-for="item in cartStore.items" :key="item.id">
                <div class="cart-item-actions">
                    <button @click="cartStore.removeFromCart(item.id)">X</button>
                </div>
                <div class="cart-item-image">
                    <router-link :to="'/rent/product/' + item.id" class="cart-item-image">
                        <img :src="'https://products-rent-cocktailservice.s3.eu-central-1.amazonaws.com/images/' + item.id + '_' + item.meta.images[0] + '.png'"
                            alt="product image" />
                    </router-link>
                </div>
                <div class="cart-item-details">
                    <p class="name_p">{{ item.name }}</p>
                    <!-- <p>{{ item.quantity }}</p>
                    <p>{{ item.days }}</p> -->
                    <input type="number" v-model.number="item.quantity"
                        min="1" class="quantity-input" />
                    <input type="number" v-model.number="item.days" 
                        min="1" class="days-input" />
                    <p class="price">{{ totalNet(item.rental_price, item.quantity, item.days) }} {{
                        translations.currency }}</p>
                    <p class="price">{{ totalBrutto(item.rental_price, item.quantity, item.days) }} {{
                        translations.currency }}</p>
                </div>

            </div>
            <div class="summary">
                <div class="add-info">
                    <textarea id="message" v-model="cartStore.add_information.message"
                        :placeholder=translations.add_information rows="5"></textarea>
                </div>
                <div class="cart-summary">

                    <div class="col">
                        <div class="label">
                            <p>{{ translations.sum }}</p>
                        </div>
                        <div class="values">
                            <p class="price">{{ cartStore.totalNet }} {{ translations.currency }}</p>
                            <p class="price">{{ cartStore.totalBrutto }} {{ translations.currency }}</p>
                        </div>
                    </div>
                    <button @click="sendAdditionalInfo">{{ translations.button }}</button>

                </div>
            </div>
        </div>
        <div class="cart-mobile">
            <div class="cart-item" v-for="item in cartStore.items" :key="item.id">
                <div class="row_1">
                    <div class="cart-item-actions">
                        <button @click="cartStore.removeFromCart(item.id)">X</button>
                    </div>
                    <div class="cart-item-image">
                        <router-link :to="'/rent/product/' + item.id" class="cart-item-image">
                            <img :src="'https://products-rent-cocktailservice.s3.eu-central-1.amazonaws.com/images/' + item.id + '_' + item.meta.images[0] + '.png'"
                                alt="product image" />
                        </router-link>
                    </div>
                    <p class="name_p">{{ item.name }}</p>
                </div>
                <div class="details">
                    <div class="row_1">
                        <span>{{ translations.table_cart.quantity }}</span>
                        <!-- <p>{{ item.quantity }}</p> -->
                        <input type="number" v-model.number="item.quantity"
                        min="1" class="quantity-input" />
                    </div>
                    <div class="row_1">
                        <span>{{ translations.table_cart.days }}</span>
                        <!-- <p>{{ item.days }}</p> -->
                        <input type="number" v-model.number="item.days" 
                        min="1" class="days-input" />
                    </div>
                    <div class="row_1">
                        <span>{{ translations.table_cart.sum_net }}</span>
                        <p>{{ totalNet(item.rental_price, item.quantity, item.days) }} {{ translations.currency }}</p>
                    </div>
                    <div class="row_1">
                        <span>{{ translations.table_cart.sum_gross }}</span>
                        <p>{{ totalBrutto(item.rental_price, item.quantity, item.days) }} {{ translations.currency }}
                        </p>
                    </div>
                </div>
            </div>
            <div class="summary">
                <div class="add-info">
                    <textarea id="message" v-model="cartStore.add_information.message"
                        :placeholder=translations.add_information rows="5"></textarea>
                </div>
                <div class="cart-summary">

                    <div class="col">
                        <div class="label">
                            <p>{{ translations.sum }}</p>
                        </div>
                        <div class="values">
                            <p class="price">{{ cartStore.totalNet }} {{ translations.currency }}</p>
                            <p class="price">{{ cartStore.totalBrutto }} {{ translations.currency }}</p>
                        </div>
                    </div>
                    <button @click="sendAdditionalInfo">{{ translations.button }}</button>

                </div>
            </div>
        </div>
        <AppFooter />
    </div>
</template>

<script>
import translationsData from '@/assets/text_lang/translations.json';
import { useLanguageStore } from '@/theme';
import { computed } from 'vue';
import { useCartStore } from '../../store/cart';

import AppFooter from './FootBar.vue';

export default {
    components: {
        AppFooter
    },
    data() {
        return {
            additionalInfo: '', // Przechowywanie wartości textarea
        };
    },
    setup() {
        const languageStore = useLanguageStore();
        const cartStore = useCartStore();
        const translations = computed(() => {
            return translationsData[languageStore.currentLanguage]["cart"];
        });

        return {
            translations,
            cartStore,
        };
    },
    computed: {
        translatedQuantity() {
            return this.quantity === 1 ? this.translations.pc : this.translations.pcs;
        },
    },
    methods: {
        totalNet(price, quantity, days) {
            return price * quantity * days;
        },
        totalBrutto(price, quantity, days) {
            const net = this.totalNet(price, quantity, days) * 1.23;
            return net.toFixed(2); // Dodanie 23% do ceny netto
        },
        sendAdditionalInfo() {
            if (this.cartStore.items.length > 0) {
                this.$router.push('/cart/summary');
            }

        },

    }
};
</script>
<style scoped>
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body,
html {
    height: 100%;
    margin: 0;
    display: flex;
    flex-direction: column;
}

#app {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    /* Wymusza, aby kontener miał minimalną wysokość równą wysokości okna przeglądarki */
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    transform: scale(1.25); 
}

.shop {
    flex: 1;
    /* Umożliwia rozciągnięcie zawartości w pionie */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* Rozdziela główną zawartość od stopki */
    align-items: center;
    padding-top: 290px;
    background-color: var(--dark-color-font);
    color: var(--dark-color-background);
    width: 100%;
    height: 100%;
    padding-left: 30px;
    padding-right: 30px;

}

.shop .header {
    width: 90%;
    /* Ustawienie szerokości na 100% */
    max-width: 1100px;
    text-align: left;
    /* Wyrównanie tekstu do lewej */
    /* Dodanie marginesu poniżej nagłówka, jeśli potrzebne */
}

.shop .header .selected-category {
    color: var(--dark-color-background);
    margin: 0;
    /* Ustawienie marginesu na 0, aby wyrównać tekst do lewej */
    margin-bottom: 40px;
}

.shop .header span {
    font-size: 12px;
    color: var(--dark-color-background);
}

.shop .header .warning {
    padding-top: 20px;
    font-size: 12px;
    color: var(--dark-color-red);

}

.cart {
    font-size: 8pt;
    width: 90%;
    max-width: 1100px;
    margin-top: 40px;
}

.cart-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
    border-bottom: 2px solid var(--dark-color-background);
    font-weight: bold;
}


.cart-header,
.cart-summary {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
}

.cart-header .name {
    width: 43%;
    /* Szerokość 33% dla kolumny "Produkt" */
    text-align: center;
    /* Wyrównanie tekstu do lewej */
}

.cart-header span:not(.name) {
    flex: 1;
    /* Równa szerokość dla pozostałych nagłówków */
    text-align: center;
    /* Wyśrodkowanie tekstu */
}

.cart-item {
    display: flex;
    align-items: center;
    padding: 10px 0;
    border-bottom: 2px solid var(--dark-color-background);
}

.cart-item-image {
    margin-right: 20px;
    /* Dodaj większy odstęp po prawej stronie obrazu */
}


.cart-item-image img {
    width: 50px;
    height: auto;
}


.cart-item-details {
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    padding-left: 20px;
    /* Dodaj dodatkowy odstęp od lewej */
}


.cart-item-details .name_p {
    text-align: left;
    /* Wyrównanie do lewej */
    width: 33%;
    /* Wymuszenie szerokości */
}


.cart-item-details input,
.cart-item-details p:not(.name_p) {
    border-left: 1px solid var(--dark-color-background);
    padding: 0 10px;
    flex: 1;
    /* Równa szerokość dla pozostałych nagłówków */
    text-align: center;

}

.cart-item-details input,
.cart-item-details .price {
    font-size: 12px;
    color: var(--dark-color-red);
    width: 10%;
    border: none;
}




.cart-item-actions {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
}

.cart-item-actions button {
    background: none;
    border: none;
    color: var(--dark-color-red);
    border: 1px solid transparent;
    font-size: 14px;
    cursor: pointer;
    padding: 10px;
    margin-left: 5px;
}

.cart-item-actions button:hover {
    background: none;
    border: none;
    color: var(--dark-color-red);
    border: 1px solid var(--dark-color-red);
    background: rgba(200, 200, 200, 0.2);
    /* Delikatnie szare, półprzezroczyste tło */
    font-size: 14px;
    cursor: pointer;
    padding: 10px;
}


.summary {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    width: 100%;
    padding: 10px 0;
    border-top: 1px solid var(--dark-color-background);
}

.add-info {
    flex: 1;
    padding: 10px;
    text-align: left;
}

.add-info textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid var(--dark-color-background);
    background: var(--dark-color-font);
    color: var(--dark-color-background);
    display: flex;
    text-align: left;
    align-items: center;
    /* Wyrównanie pionowe */
    justify-content: center;
    /* Wyrównanie poziome */
    border-radius: 2px;
}


.cart-summary {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    width: 30%;
    padding: 10px 0;
    padding-left: 10px;
    font-weight: bold;

}

.cart-summary .col {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px 10px;
    padding-bottom: 20px;
    border-bottom: 2px solid var(--dark-color-background);
}

.cart-summary .col .label {
    flex: 0 0 20%;
    /* 20% szerokości dla etykiety */
    text-align: left;
    padding-left: 10px;
}

.cart-summary .col .values {
    flex: 0 0 80%;
    /* 80% szerokości dla wartości */
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
}

.cart-summary .col .values .price {
    flex: 1;
    color: var(--dark-color-red);
    text-align: center;
    padding: 0 10px;
    border-left: 1px solid var(--dark-color-background);
}

.cart-summary button {
    width: 100%;
    height: 60%;
    background: var(--dark-color-background);
    color: var(--dark-color-font);
    border: 1px solid transparent;
    padding: 10px 20px;
    margin-top: 20px;
    cursor: pointer;
    font-size: 14px;
    border-radius: 2px;
}

.cart-summary button:hover {
    background: var(--dark-color-font);
    color: var(--dark-color-background);
    border: 1px solid var(--dark-color-background);
    padding: 10px 20px;
    cursor: pointer;
    font-size: 14px;
    border-radius: 2px;
}

@media (min-width: 801px) {
    .cart-mobile {
        visibility: hidden;
        display: none;
    }
}

@media (max-width: 800px) {
    .shop {
        padding-top: 120px;

    }

    .cart {
        visibility: hidden;
        display: none;
    }

    .cart-mobile {
        margin-top: 30px;
        width: 90%;
        border-top: 2px solid var(--dark-color-background);
        border-bottom: 2px solid var(--dark-color-background);
        margin-bottom: 30px;
    }

    .row_1 {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 10px 0;
        border-bottom: 1px solid var(--dark-color-background);
        width: 100%;
    }

    .row_1 input {
        width: 10%;
        height: 20px;
        font-size: 10px;
        padding: 0px;
        text-align: center;
        border: none;
        margin: 0px;
    }

    .cart-item {
        font-size: 10px;
        font-weight: normal;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border: none;
    }

    .cart-item-image img {
        width: 40px;
        height: auto;
    }

    .details {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .summary {
        display: flex;
        flex-direction: column;
        /* Układ pionowy */
        align-items: stretch;
        /* Dopasowanie szerokości do rodzica */
        width: 100%;
        /* Ustawienie na pełną szerokość ekranu */
        padding: 10px 0;
        border-top: 1px solid var(--dark-color-background);
    }

    .summary .add-info {
        width: 100%;
        /* Cała szerokość ekranu */
        text-align: center;
        margin-bottom: 20px;
        padding: 0px;
    }

    .summary .add-info textarea {
        width: 100%;
        /* Dopasowanie do kontenera */
        font-size: 12px;
        border-radius: 2px;
    }

    .summary .cart-summary {
        width: 100%;
        /* Cała szerokość ekranu */
        display: flex;
        flex-direction: column;
        /* Układ pionowy */
        gap: 10px;
        /* Odstęp między elementami */
        text-align: center;
        /* Wyśrodkowanie tekstu */
    }

    .summary .cart-summary .col {
        width: 100%;
        /* Pełna szerokość */
        display: flex;
        font-size: 12px;

        justify-content: space-between;
        /* Rozkład wartości */
        align-items: center;
        padding: 10px 0;
        border-bottom: 1px solid var(--dark-color-background);
    }

    .summary .cart-summary .col .label {
        flex: 1;
        text-align: left;
        /* Wyrównanie tekstu do lewej */
    }

    .summary .cart-summary .col .values {
        flex: 1;
        text-align: right;
        /* Wyrównanie wartości do prawej */
        font-weight: bold;
    }

    .summary .cart-summary button {
        width: 100%;
        /* Cała szerokość */
        padding: 15px;
        font-size: 14px;
        background: var(--dark-color-background);
        color: var(--dark-color-font);
        border: none;
        border-radius: 4px;
        cursor: pointer;
        text-transform: uppercase;
    }

    .summary .cart-summary button:hover {
        background: var(--dark-color-font);
        color: var(--dark-color-background);
    }
}
</style>