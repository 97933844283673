<template>
  <div id="app" class="shop">
    <ImageCarouselRent v-if="category.name === 'home'" />
    <div class="view-toggle" v-if="!isMobile">
      <button @click="listView"><img src="@/assets/icons/list.png" /> </button>
      <button @click="gridView"><img src="@/assets/icons/grid.png" /> </button>
    </div>
    <RelatedProducts v-if="category.name === 'dhome'" />

    <div class="vertical-line"></div>
    <div class="header" v-if="category.name != 'home'">
      <p class="selected-category">{{ category.name }}</p><br>
      <span>{{ category.desc }}</span>
    </div>

    <div v-if="isLoading" class="loading-spinner"></div>
    <div id="specific-div-id" v-if="!isLoading" :class="['product-list', { 'list-view': isListView || isMobile }]">
      <div v-for="(product, index) in products" :key="index" class="product-item">
        <img
          :src="'https://products-rent-cocktailservice.s3.eu-central-1.amazonaws.com/images/' + product.id + '_' + product.meta.images[0] + '.png'"
          alt="image" class="product-image" />
        <div class="product-details">
          <div class="prod-info">
            <p class="name-prod">{{ product.name }}</p>
            <p class="price">{{ formatRentalPrice(product.rental_price) }}</p>
          </div>
          <router-link :to="`/rent/product/${product.id}`" class="product-button">{{ translations.product_button
            }}</router-link>
        </div>
      </div>
    </div>
  </div>
  <AppFooter />
</template>

<script>
import ImageCarouselRent from './ImageCarouselRent.vue';
import RelatedProducts from './RelatedProducts.vue';


import { ref, computed, onMounted, watchEffect, watch } from 'vue';
import translationsData from '@/assets/text_lang/translations.json';
import { useCategoryStore, useLanguageStore, useSearchStore } from '@/theme';

import { useCartStore } from '../../store/cart';

import { pl, enUS } from 'date-fns/locale';



import AppFooter from './FootBar.vue';

export default {
  name: 'ShopPage',
  components: {
    ImageCarouselRent,
    RelatedProducts,
    AppFooter
  },
  data() {
    return {
      isListView: false,
      isMobile: false,
      relatedProducts: [],
      currentIndex: 0,
      visibleCount: 3,

      searchQuery: '',

      deliveryDate: '',
      deliveryTime: '',
      pickupDate: '',
      pickupTime: '',

      formattedDeliveryTime: '',
      formattedPickupTime: '',
      showDeliveryDropdown: false,
      showPickupDropdown: false,
      times: this.generateTimeOptions(),
    };
  },
  setup() {
    const cartStore = useCartStore();
    const languageStore = useLanguageStore();
    const categoryStore = useCategoryStore();
    const translations = computed(() => {
      return translationsData[languageStore.currentLanguage]["rent"];
    });
    const currentLanguage = computed(() => languageStore.currentLanguage);

    const locale = ref(enUS);
    watch(currentLanguage, (newLang) => {
      if (newLang === 'pl') {
        locale.value = pl;
      } else {
        locale.value = enUS;
      }
    }, { immediate: true });

    const changeLanguage = (lang) => {
      languageStore.setLanguage(lang);
    };
    const currentCategory = computed(() => categoryStore.currentCategory);
    const category = computed(() => translations.value.menu_category[currentCategory.value]);

    const products = ref([]);
    const isLoading = ref(true); // Dodaj zmienną isLoading
    const cache = {}; // Zwykły obiekt wystarczy, gdyż nie zmieniamy jego referencji

    const searchStore = useSearchStore();

    watchEffect(() => {
      if (!searchStore.searchQuery.trim()) {
        // Przywraca pełną listę produktów
        products.value = cache[currentCategory.value] || [];
      } else {

        const element = document.getElementById('specific-div-id');
        if (element) {
          const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
          const offsetPosition = elementPosition - 300;

          window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth',
          });
        }

        // Filtruje produkty po frazie wyszukiwania
        products.value = (cache[currentCategory.value] || []).filter(product =>
          product.name.toLowerCase().includes(searchStore.searchQuery.toLowerCase())
        );
        if (products.value.length === 0) {
          console.log('Brak wyników wyszukiwania');
          categoryStore.setCategory('home');
        }


      }
    });

    const fetchProductsByCategory = async (category) => {
      isLoading.value = true;
      if (cache[category]) {
        products.value = cache[category]; // Pobierz dane z cache
        isLoading.value = false;
        return;
      }
      try {
        const url =
          category === 'home'
            ? `https://l4887ivvna.execute-api.eu-central-1.amazonaws.com/dev/products`
            : `https://l4887ivvna.execute-api.eu-central-1.amazonaws.com/dev/products?category=${category}`;
        const response = await fetch(url);
        const data = await response.json();
        products.value = data.products;
        cache[category] = data.products;
      } catch (error) {
        console.error("Błąd podczas pobierania produktów:", error);
      } finally {
        isLoading.value = false; // Ustaw isLoading na false po zakończeniu pobierania
      }
    };
    onMounted(() => {
      fetchProductsByCategory(currentCategory.value);
    });

    // Reaguje na zmiany w currentCategory, wywołując fetchProductsByCategory
    watchEffect(() => {
      const categoryValue = currentCategory.value;
      if (categoryValue) {
        fetchProductsByCategory(categoryValue);
      }


    });
    return {
      cartStore,
      products,
      locale,
      category,
      currentCategory,
      currentLanguage,
      changeLanguage,
      languageStore,
      translations,
      isLoading,
    }
  },
  computed: {
    visibleProducts() {
      return this.relatedProducts.slice(this.currentIndex, this.currentIndex + this.visibleCount);
    }
  },
  methods: {
    generateTimeOptions() {
      const options = [];
      for (let h = 0; h < 24; h++) {
        for (let m = 0; m < 60; m += 30) {
          const hour = String(h).padStart(2, '0');
          const minute = String(m).padStart(2, '0');
          options.push(`${hour}:${minute}`);
        }
      }
      return options;
    },
    selectDeliveryTime(time) {
      this.cartStore.add_information.deliveryTime = time;
      this.showDeliveryDropdown = false;
    },
    hideDeliveryDropdown() {
      setTimeout(() => {
        this.showDeliveryDropdown = false;
      }, 200);
    },
    selectPickupTime(time) {
      this.cartStore.add_information.pickupTime = time;
      this.showPickupDropdown = false;
    },
    hidePickupDropdown() {
      setTimeout(() => {
        this.showPickupDropdown = false;
      }, 200);
    },


    openTimePicker(event) {
      event.target.showPicker(); // Wymusza otwarcie wbudowanego wyboru czasu
    },
    formatRentalPrice(rental_price) {
      if (rental_price === "<price_individually>") {
        return this.translations.price_individually;
      }
      const suffix = this.translations.price_suffix;
      const currency = this.translations.currency;
      return `${rental_price} ${currency} ${suffix}`;
    },
    listView() {
      this.isListView = true;
    },
    gridView() {
      this.isListView = false;
    },
    checkMobile() {
      this.isMobile = window.innerWidth <= 768;
    },
    nextSlide() {
      if (this.currentIndex < this.relatedProducts.length - this.visibleCount) {
        this.currentIndex++;
      }
    },
    prevSlide() {
      if (this.currentIndex > 0) {
        this.currentIndex--;
      }
    },
    // submitSearch() {
    //   const category = this.currentCategory.value;

    //   if (this.searchQuery.trim() === '') {
    //     this.products = this.cache[category] || [];
    //     return;
    //   }

    //   if (this.cache[category]) {
    //     const filteredProducts = this.cache[category].filter(product =>
    //       product.name.toLowerCase().includes(this.searchQuery.toLowerCase())
    //     );
    //     filteredProducts.sort((a, b) => a.name.localeCompare(b.name));
    //     this.products = filteredProducts;
    //   } else {
    //     console.warn('Brak danych w pamięci podręcznej dla tej kategorii');
    //   }
    // },
  },
  mounted() {
    this.checkMobile(); // Sprawdź na początku
    window.addEventListener('resize', this.checkMobile); // Sprawdzenie przy każdej zmianie rozmiaru okna
  },
  beforeUnmount() {  // Zamiast beforeDestroy
    window.removeEventListener('resize', this.checkMobile); // Usunięcie listenera przy niszczeniu komponentu
  },
};
</script>

<style module>
input {
  font-family: 'MyFont', sans-serif;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
  height: 40px;
  font-size: 10pt;
  border: 1px solid var(--dark-color-background);
  background-color: var(--dark-color-font);
  border-radius: 2px;
}
</style>

<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body,
html {
  height: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
}

#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  /* Wymusza, aby kontener miał minimalną wysokość równą wysokości okna przeglądarki */
}

.shop {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-top: 220px;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  background-color: var(--dark-color-font);
  color: var(--dark-color-background);
  width: 100%;
  min-height: calc(100vh - 70px);
}

.shop .view-toggle {
  position: relative;
  left: 40%;
  margin-top: 20px;
}

.shop .header {
  width: 90%;
  margin: 50px;
  white-space: pre-line;
  max-width: 1400px;
  text-align: left;
}

.shop .vertical-line {
  margin-top: 20px;
  height: 2px;
  width: 90%;
  background-color: var(--dark-color-background);
}

.shop .header .selected-category {
  font-size: 25px;
  color: var(--dark-color-background);
  width: 90%;
  margin-bottom: 10px;
}

.shop .header span {
  font-size: 12px;
  color: var(--dark-color-background);
}

.shop .view-toggle button {
  width: 32px;
  height: 32px;
  border: 1px solid transparent;
  background: none;
  /* Usuwa tło przycisku */

  padding: 0;
}

.shop .view-toggle img {
  width: 30px;
  height: 30px;
  border: 1px solid transparent;
}

.shop .view-toggle button:hover {
  border: 1px solid var(--dark-color-background);
  background-color: var(--dark-color-background);
  /* color: var(--dark-color-font); */
}

.shop .view-toggle button:hover img {
  filter: invert(94%) sepia(5%) saturate(402%) hue-rotate(317deg) brightness(105%) contrast(85%);
}

.view-toggle {
  margin-bottom: 0px;
}


.v3dp__popou {
  width: 100%;
}

.custom-vdp-style {
  width: 100%;
  height: 100%;
  border: 1px solid var(--dark-color-background);
  background-color: var(--dark-color-font);
}

input {
  width: 100%;
  height: 100%;
  border: 1px solid var(--dark-color-background);
  background-color: var(--dark-color-font);
}



/* # Kalendarz  */

::v-deep .v3dp__popout {
  left: 2px;
  width: 100% !important;
  max-width: none !important;
}


.delivery-pickup-form {
  margin: 50px;
  width: 90%;
  max-width: 1400px;
}

.delivery-pickup-form p {
  font-size: 12px;
  color: var(--dark-color-red);
  margin-bottom: 10px;
}

.form-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
  row-gap: 20px;
}

.form-group {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

label {
  margin-bottom: 5px;
  font-weight: bold;
  width: 100%;
  height: 100%;
}



.custom-time-picker {
  position: relative;
  width: 100%;
  border: 1px solid var(--dark-color-background);
}

.custom-time-picker input {
  padding-left: 10px;
  height: 45px;
}

.dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  color: var(--dark-color-background);
  background-color: var(--dark-color-font);
  border: 1px solid var(--dark-border-color);
  max-height: 150px;
  overflow-y: auto;
  width: 100%;
  z-index: 1000;
}

.dropdown li {
  padding: 5px 10px;
  cursor: pointer;
}

.dropdown li:hover {
  background-color: var(--dark-color-background);
  color: var(--dark-color-font);
}




.product-list {
  display: flex;
  margin: 50px;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 90%;
  height: 100%;
  max-width: 1400px;
}

.product-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
  height: auto;
  max-width: 33%;
  text-align: left;
  width: 50%;
}

.product-list.list-view .product-item {
  flex-direction: row;
  max-width: 100%;
  border-top: 2px solid var(--dark-color-background);
  width: 100%;
}

.product-list img {
  width: 120px;
  height: 120px;
}

.product-details {
  margin: 20px;
  padding-left: 20px;
  border-left: 2px solid var(--dark-color-background);
}

.product-list.list-view .product-details {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  border-left: none;
  padding: 0px;
}


.product-list .product-details .prod-info {
  height: 100px;
  margin-bottom: 20px;
}

.product-list.list-view .product-details .prod-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 20px;
  margin-bottom: 0px;
  width: 100%;
}

.product-list .product-details .prod-info .name-prod {
  font-size: 12px;
  line-height: 14pt;
  display: flex;
  align-items: center;
  /* Wyrównanie tekstu do góry */
  text-align: left;
  margin-bottom: 20px;
  width: 100%;
}

.product-list.list-view .product-details .prod-info .name-prod {
  width: 60%;
  height: 66px;
  border-left: 2px solid var(--dark-color-background);
  padding-left: 20px;
  font-size: 12pt;
  line-height: 25pt;
  display: flex;
  align-items: center;
  margin-bottom: 0px;
  /* Wyrównanie tekstu do góry */
  text-align: left;
}



.price {
  font-size: 10px;
  white-space: pre-line;
  color: var(--dark-color-red);
}


.product-list .product-details .prod-info .price {
  white-space: pre-line;
  display: flex;
  align-items: center;
  /* Wyrównanie tekstu do góry */
  text-align: left;
  font-size: 12px;
  line-height: 14pt;
}

.product-list.list-view .product-details .prod-info .price {
  border-left: 2px solid var(--dark-color-background);
  white-space: pre-line;
  padding-left: 20px;
  display: flex;
  align-items: center;
  /* Wyrównanie tekstu do góry */
  text-align: left;
  font-size: 12pt;
  line-height: 25pt;
}

.product-list .product-button {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: var(--dark-color-background);
  color: var(--dark-color-font);
  letter-spacing: 2px;
  font-size: 12px;
  border: 1px solid transparent;
  transition: background 0.3s, color 0.3s;
  text-decoration: none;
  width: 100px;
  height: 30px;
}

.product-list.list-view .product-button {
  font-size: 14pt;
  text-align: center;
  height: 50px;
  width: 40%;
}


.product-button:hover {
  background-color: var(--dark-color-font);
  color: var(--dark-color-background);
  border: 1px solid var(--dark-color-background);
}




.loading-spinner {
  border: 8px solid rgba(0, 0, 0, 0.1);
  border-top: 8px solid var(--dark-color-background);
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
  margin: 20px auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 1100px) {
  .shop {
    padding-top: 90px;
  }
}


/* Dla urządzeń mobilnych wymuś widok listy */
@media (max-width: 822px) {


  .product-list.list-view .product-item {
    flex-direction: row;
    width: 100%;
  }

  .product-item {
    max-width: 100%;
    flex-direction: row;
    margin: 10px;
    margin-bottom: 0px;
    padding: 10px;
  }

  .product-list.list-view .product-button {
    display: flex;
    justify-content: center;
    /* Wyrównanie poziome */
    align-items: center;
    font-size: 14px;
    width: 120px;
    height: 35px;
  }

  .product-list img {
    width: 80px;
    height: 80px;
  }

  .product-list.list-view .product-details {
    flex-direction: column;
    border-left: 2px solid var(--dark-color-background);
    width: 100%;
    text-align: left;

  }

  .product-list.list-view .product-details .prod-info {
    display: flex;
    flex-direction: column;
    padding-top: 0px;
    padding: 0;
    margin: 0;
    border: none;
    text-align: left;
    font-size: 10px;
    margin-left: 30px;
  }

  .product-list.list-view .product-details .prod-info .price {
    width: 100%;
    font-size: 10px;
    line-height: 14pt;
    border-left: none;
    padding: 0;
    text-align: left;
  }

  .product-list.list-view .product-details .prod-info .name-prod {
    width: 100%;
    font-size: 10px;
    line-height: 14pt;
    border-left: none;
    padding: 0;
    text-align: left;
  }

  .product-list.list-view .product-button {
    font-size: 10pt;
    text-align: center;
    font-weight: normal;
    height: 20px;
    width: 100%;
    margin-left: 30px;
  }

  .shop .header {
    width: 90%;
    font-size: 12px;
    padding: 10px;
    /* Ustawienie szerokości na 100% */
    text-align: left;
    margin: 20px 20px 20px 20px;
  }


}
</style>
