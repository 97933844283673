<template>
  <div class="navbar-container">
    <nav class="navbar" :class="{ 'dark-mode': isDarkMode }">
      <div class="navbar-logo">
        <router-link to="/" @click="resetValue">
          <img :src="logoSrc" alt="Logo" />
        </router-link>
      </div>

      <div class="navbar-toggle" @click="toggleMenu" @mouseover="handleMouseOver" @mouseleave="handleMouseLeave">
        <span></span>
        <span></span>
        <span></span>
      </div>

      <ul v-if="!isMobile" class="navbar-list" @mouseover="handleMouseOver" @mouseleave="handleMouseLeave">
        <li v-for="(item, index) in menuItems" :key="index" @mouseover="handleSubmenuMouseOver(index)"
          @mouseleave="handleSubmenuMouseLeave">
          <router-link :to="item.link" exact-active-class="active" @click="resetValue(index)">
            {{ item.name }}
          </router-link>
          <ul v-if="item.subItems" class="dropdown-menu" :class="{ active: isSubmenuOpen === index || isMobileMenu }">
            <li v-for="(subItem, subIndex) in item.subItems" :key="subIndex">
              <router-link :to="subItem.link" exact-active-class="active" @click="resetValue(index)">
                {{ subItem.name }}
              </router-link>
            </li>
          </ul>
        </li>
      </ul>


      <div class="vertical-line"></div>
      <div class="language-select">
        <ul class="nav-menu">
          <li>
            <button @click="changeLanguage('pl')" :class="{ 'active': currentLanguage === 'pl' }"
              exact-active-class="active">POL</button>
            <button @click="changeLanguage('en')" :class="{ 'active': currentLanguage === 'en' }"
              exact-active-class="active">ENG</button>
          </li>
        </ul>
      </div>
    </nav>

    <nav v-if="!isMobile && isEventPage" class="navbar-additional" :class="{ 'dark-mode': isDarkMode }">
      <ul class="navbar-list" :class="{ active: isOpen || isHovered }" @mouseover="handleMouseOver"
        @mouseleave="handleMouseLeave">
        <li v-for="(item, index) in menuItemsAdd" :key="index" @mouseover="handleSubmenuMouseOver(index)"
          @mouseleave="handleSubmenuMouseLeave">
          <router-link :to="item.link" :class="{ 'active': slideStoreIndex - 1 === index }"
            @click="eventRouterMenu(item.index)">
            {{ item.name }}
          </router-link>
          <ul v-if="item.subItems" class="dropdown-menu" :class="{ active: isSubmenuOpen === index || isMobileMenu }">
            <li v-for="(subItem, subIndex) in item.subItems" :key="subIndex">
              <router-link :to="subItem.link" exact-active-class="active" @click="eventRouterMenu(subItem.index)">{{
                subItem.name
              }}</router-link>
            </li>
          </ul>
        </li>
      </ul>
    </nav>


    <nav v-if="isMobile" class="mobile-menu" @mouseover="handleMouseOver" @mouseleave="handleMouseLeave">
      <ul class="mobile-menu-list" :class="{ active: isMobileMenuOpen }">

        <li v-for="(item, index) in menuItems" :key="index" @mouseover="handleSubmenuMouseOver(index)"
          @mouseleave="handleSubmenuMouseLeave">
          <button @click="resetValue(index, item.link)"
            :class="{ active: router.currentRoute.value.path === item.link }">
            {{ item.name }}
          </button>
          <ul v-if="index === 2">
            <li v-for="(subItem, subIndex) in menuItemsAdd" :key="subIndex">
              <button @click="eventRouterMenu(subItem.index)"
                :class="{ 'active': slideStoreIndex === subItem.index && $route.path.startsWith('/event') }">
                {{ subItem.name }}
              </button>
            </li>
          </ul>

        </li>
        <li>
          <button @click="changeLanguage('pl')" :class="{ 'active': currentLanguage === 'pl' }"
            exact-active-class="active">POL</button>
          <button @click="changeLanguage('en')" :class="{ 'active': currentLanguage === 'en' }"
            exact-active-class="active">ENG</button>
        </li>
      </ul>
    </nav>





  </div>
</template>

<script>
import { computed, ref } from 'vue';
import { useSlideStore } from '../../store/slideStore';
import translationsData from '@/assets/text_lang/translations.json';
import { useLanguageStore } from '@/theme';
import { useRouter } from 'vue-router';

export default {
  data() {
    return {
      addMenu: false,
    };
  },
  setup() {
    const router = useRouter();
    const slideStore = useSlideStore();
    const languageStore = useLanguageStore();
    const isMobileMenuOpen = ref(false);
    const translations = computed(() => {
      return translationsData[languageStore.currentLanguage]["nav_bar"];
    });
    const isOpen = ref(false);
    const currentLanguage = computed(() => languageStore.currentLanguage);
    const changeLanguage = (lang) => {
      languageStore.setLanguage(lang); // Zmieniamy język w store
      isOpen.value = false;  // Zamykamy menu
      if (isMobile.value) {
        isMobileMenuOpen.value = false;
      }
    };


    const isHovered = ref(false);
    const isSubmenuOpen = ref(null);
    const hoverTimeout = ref(null);
    const isDarkMode = ref(true);
    const isMobile = ref(window.innerWidth <= 1400);
    const menuItems = computed(() => [
      { name: translations.value.home, link: '/' },
      { name: translations.value.about_us, link: '/about' },
      { name: translations.value.event, link: '/event', addMenu: true },
      { name: translations.value.rental, link: '/rent' },
      { name: translations.value.realizations, link: '/portfolio' },
      { name: translations.value.contact, link: '/contact' }
    ]);
    const menuItemsAdd = computed(() => [
      { name: translations.value.event_add_menu.company, link: '/event', index: 1 },
      { name: translations.value.event_add_menu.brand, link: '/event', index: 2 },
      { name: translations.value.event_add_menu.mass, link: '/event', index: 3 },
      { name: translations.value.event_add_menu.occasional, link: '/event', index: 4 },
      { name: translations.value.event_add_menu.bar_zones, link: '/event', index: 5 }
    ]);

    const slideStoreIndex = computed(() => slideStore.slideIndex);
    return {
      router,
      slideStoreIndex,
      currentLanguage,
      changeLanguage,
      isMobileMenuOpen,
      translations,
      isOpen,
      isHovered,
      isSubmenuOpen,
      hoverTimeout,
      isDarkMode,
      isMobile,
      menuItems,
      menuItemsAdd
    };
  },
  computed: {
    logoSrc() {
      return require('@/assets/icons/Cocktail-Service-logo-RGB-WHITE.png');
    },
    isIndexSlide() {
      return this.slideStoreIndex;
    },
    isEventPage() {
      return this.$route.path.startsWith('/event');
    },
  },
  methods: {
    resetValue(index, link) {

      this.isMobileMenuOpen = false;
      const slideStore = useSlideStore();
      slideStore.setSlideIndex(0);

      const selectedItem = this.menuItems[index];
      if (selectedItem && Object.prototype.hasOwnProperty.call(selectedItem, 'addMenu')) {
        this.addMenu = selectedItem.addMenu;
      } else {
        this.addMenu = false;
      }

      if (link) {
        this.$router.push(link);
      }
    },
    eventRouterMenu(index) {
      this.isMobileMenuOpen = false;
      const slideStore = useSlideStore();
      slideStore.setSlideIndex(index);
      if (this.$route.path !== '/event') {
        this.$router.push('/event');
      }
    },
    toggleMenu() {
      this.isMobileMenuOpen = !this.isMobileMenuOpen;
    },
    handleMouseOver() {
      clearTimeout(this.hoverTimeout);
      this.isMobileMenuOpen = true;
    },
    handleMouseLeave() {
      this.hoverTimeout = setTimeout(() => {
        this.isMobileMenuOpen = false;
      }, 300);
    },
    handleSubmenuMouseOver(index) {
      this.isSubmenuOpen = index;
    },
    handleSubmenuMouseLeave() {
      this.isSubmenuOpen = null;
    },
    handleClickOutside(event) {
      if (!this.isMobile) {
        return;
      }
      const menu = this.$el.querySelector('.mobile-menu-list'); // Znajdź rozwijane menu
      const toggleButton = this.$el.querySelector('.navbar-toggle'); // Znajdź przycisk toggle

      // Sprawdź, czy kliknięto poza menu i przyciskiem toggle
      if (this.isMobileMenuOpen && !menu.contains(event.target) && !toggleButton.contains(event.target)) {
        this.isMobileMenuOpen = false;
      }
    },
    checkIsMobile() {
      this.isMobile = window.innerWidth <= 1400;
    },
    toggleDarkMode() {
      this.isDarkMode = !this.isDarkMode;
      document.body.classList.toggle('dark-mode', this.isDarkMode);
      localStorage.setItem('isDarkMode', this.isDarkMode);
    }
  },
  mounted() {
    window.addEventListener('resize', this.checkIsMobile);
    this.checkIsMobile();
    this.isMobileMenuOpen = false;
    document.addEventListener('click', this.handleClickOutside);
  },
  beforeUnmount() {  // Zmienione z beforeDestroy
    window.removeEventListener('resize', this.checkIsMobile);
    document.removeEventListener('click', this.handleClickOutside);
  },
};
</script>

<style scoped>
.navbar-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* max-height: 18%; */
  position: fixed;
  z-index: 1000;
  letter-spacing: 3px;
}


.navbar {
  font-size: 14px;
  width: 100%;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  background-color: var(--light-color-background);
  transition: background-color 0.3s ease, color 0.3s ease;
  height: 100px;
}

.navbar-additional {
  font-size: 14px;
  width: 100%;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  background-color: var(--dark-color-background);
  transition: background-color 0.3s ease, color 0.3s ease;
  height: 8%;
  border-top: 2px solid var(--dark-color-font);
}

.navbar-additional.active {
  opacity: 1;
  /* Pełna widoczność */
  visibility: visible;
  /* Menu jest widoczne */
  max-height: 200px;
  /* Wysokość menu */
}


.navbar-additional .navbar-list {
  display: flex;
  justify-content: center;
  /* Wyśrodkowanie elementów listy */
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
}

.navbar.dark-mode {
  background-color: var(--dark-color-background);
}

.navbar-logo {
  position: absolute;
  left: 10px;
}

.navbar-logo router-link {
  display: inline-block;
}

.navbar-logo img {
  height: 120px;
  width: auto;
  max-width: 100%;
}

.dark-mode-logo img {
  height: 20px;
  width: auto;
  max-width: 100%;
}

.navbar-list {
  display: flex;
  list-style: none;
  justify-content: flex-end;
  flex-grow: 1;
  margin: 0;
  margin-right: 25px;
  padding: 0;
}


.navbar-list.active {
  max-height: 200px;
  opacity: 1;
  visibility: visible;
}

.navbar-list li {
  position: relative;
}

.navbar-list li a {
  display: block;
  color: var(--light-color-font);
  padding: 20px 40px;
  /* padding: 5px 5px; */
  /* margin: 20px 20px; */
  text-decoration: none;
  transition: color 0.3s ease, background-color 0.3s ease, border-bottom 0.3s ease;
  border-bottom: 2px solid transparent;
}

.dark-mode .navbar-list li a {
  color: var(--dark-color-font);
}

.navbar-list li a:hover {
  border-bottom: 2px solid var(--dark-color-font)
}

.navbar-list li .active {
  border-bottom: 2px solid var(--dark-color-font);
  /* Widoczne dolne obramowanie */
  color: var(--dark-color-font);
  /* Możesz także zmienić kolor tekstu, jeśli chcesz */
}


.navbar-toggle {
  position: absolute;
  right: 40px;
  display: none;
  flex-direction: column;
  cursor: pointer;
  background: none;
  border: none;
}

.navbar-toggle span {
  height: 3px;
  width: 25px;
  background-color: var(--dark-color-background);
  margin: 4px 0;
  border-radius: 1px;
}

.dark-mode .navbar-toggle span {
  background-color: var(--dark-color-font);
}

.navbar button {
  margin-right: 50px;
}

.dark-mode-toggle {
  background-color: transparent;
  border: 1px solid #0C1826;
  color: #0C1826;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 4px;
}



.dark-mode .dark-mode-toggle {
  border-color: var(--dark-color-font);
  color: var(--dark-color-font);
}

.dark-mode-toggle:hover {
  background-color: rgba(255, 255, 255, 0.2);
}


.nav-menu {
  display: flex;
  /* Ustawia elementy w poziomej linii */
  list-style: none;
  padding: 0;
  margin: 0;
}


.nav-menu li button {
  display: block;
  font-size: 14px;
  color: var(--light-color-font);
  background: none;
  padding: 20px 20px;
  border: none;
  margin: 0px;
  text-decoration: none;
  transition: color 0.3s ease, background-color 0.3s ease, border-bottom 0.3s ease;
  border-bottom: 2px solid transparent;
}

.dark-mode .nav-menu li button {
  color: var(--dark-color-font);
}

.nav-menu li button:hover {
  border-bottom: 2px solid var(--dark-color-font)
}

.nav-menu li button.active {
  border-bottom: 2px solid var(--dark-color-font);
}



.language-select {
  display: flex;
  /* Ustawia flexbox na kontenerze */
  justify-content: center;
  /* Centruje zawartość poziomo */
  align-items: center;
  /* Centruje zawartość pionowo, jeśli kontener ma większą wysokość */
  height: auto;
  width: auto;
  color: var(--dark-color-font);
  /* border-left: 1px solid var(--dark-color-font); */
  margin-right: 5px;
}

.language-select ul {
  margin: auto;
  margin-left: 25px;
  margin-right: 25px;
}

.language-select li {
  /* margin-top: 48px; */

  display: flex;
  /* Ustawia elementy wewnątrz listy poziomo */
}


.language-select li a {
  display: block;
  color: var(--light-color-font);
  padding: 20px 20px;
  /* Zwiększenie paddingu dla większego obszaru podświetlenia */
  text-decoration: none;
  transition: color 0.3s ease;
  /* border-bottom:  0.3s ease transparent; */
  border-bottom: 2px solid transparent;
}

.language-select a:hover {
  border-bottom: 2px solid var(--dark-color-font);
  /* Widoczne dolne obramowanie */
}

.vertical-line {
  width: 2px;
  height: 125px;
  background-color: var(--dark-color-font);
}


.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  /* To ensure the dropdown appears below the menu item */
  left: 0;
  /* Aligns dropdown with the left side of the menu item */
  background-color: var(--light-color-background);
  /* box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); */
  z-index: 1000;
  min-width: 180px;
  padding: 10px 0;
  list-style: none;
}

.dropdown-menu li {
  padding: 8px 16px;
  width: 300px;
}

.dropdown-menu li a {
  color: var(--dark-color-font);
  text-decoration: none;
  font-size: 10px;
}

.navbar-list li .dropdown-menu.active {
  display: block;
  border: none;
}

.dark-mode .dropdown-menu {
  background-color: var(--dark-color-background);
}

/* @media (max-width: 700px) {
  .navbar {
    height: 40px;
  }
} */




.mobile-menu {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  padding: 0px;
  list-style: none;
  background-color: var(--dark-color-background);
}


.mobile-menu-list {
  display: none;
  list-style: none;
  margin: 0;
  padding: 0;
}

.mobile-menu-list input {
  text-transform: uppercase;
  margin: 0 10px;
  color: var(--dark-color-background);
  /* Dostosuj szerokość pola wyszukiwania */
  height: 40px;
}


.mobile-menu-list.active {
  display: block;
}

.mobile-menu-list li {
  list-style: none;
  content: none;
  margin: 0;
  padding: 0;
  transition: color 0.3s ease, background-color 0.3s ease, border-bottom 0.3s ease;
}

.mobile-menu-list li ul {
  padding: 0;
}

.mobile-menu-list li button {
  white-space: pre;
  font-size: 10px;
  color: var(--dark-color-font);
  padding: 14px;
  text-decoration: none;
  margin: 0;
  text-align: left;
  width: 100%;
  background: none;
  border: none;
  border-bottom: 2px solid transparent;
  transition: color 0.3s ease, background-color 0.3s ease, border-bottom 0.3s ease;
}

.mobile-menu-list li button:hover {
  background-color: #b1afaf;
  color: #000;
  border-bottom: 2px solid var(--dark-color-font)
}

.mobile-menu-list li button.active {
  border-bottom: 2px solid var(--dark-color-font);
}








@media (min-width: 1570px) {
  .navbar-list li a {
    padding: 20px 45px;
  }

  .mobile-menu {
    display: none;
  }
}


@media (max-width: 1400px) {
  .navbar {
    height: 65px;
    width: 100%;
  }

  .navbar-logo img {
    height: 100px;
    width: auto;
    max-width: 100%;
  }


  .dropdown-menu-additional {
    padding: 0px 0px;
  }

  .navbar-toggle {
    padding: 20px 20px;
  }



  .navbar-toggle {
    display: flex;
    right: 50px;
  }

  .language-select,
  .vertical-line {
    visibility: hidden;
  }

}



@media (min-width: 1401px) {
  .navbar-list {
    max-height: none;
    opacity: 1;
    visibility: visible;
  }

  .navbar-list .dropdown-menu {
    display: none;
  }

  .navbar-list li .dropdown-menu.active {
    display: block;
    position: absolute;
    top: 100%;
    left: 0;
  }
}
</style>