<template>
  <div :class="{ 'dark-mode': isDarkMode }" class="carousel" @wheel="handleScroll" 
       @touchstart="handleTouchStart" @touchmove="handleTouchMove" @touchend="handleTouchEnd">
    <div class="carousel-inner" :style="{ transform: `translateX(-${slideStoreIndex * 100}%)` }">
      <div class="carousel-item" v-for="(slide, index) in slides" :key="index">
        <img :src="slide.image" :alt="slide.alt">
        <div class="overlay"></div>
        <div class="caption">
          <p>{{ translations.title[slide.alt][0] }}</p>
          <p>{{ translations.title[slide.alt][1] }}</p>
          <p>{{ translations.title[slide.alt][2] }}</p>
          <router-link :to="slide.link" class="hero-button" @click="eventRouterMenu(slide.index)">{{ translations.button }}</router-link>
        </div>
      </div>
    </div>
    <div class="carousel-controls">
      <div class="carousel-control prev">
        <button class="carousel-control" @click="prevSlide">❮</button>
      </div>
      <div class="carousel-control next">
        <button class="carousel-control" @click="nextSlide">❯</button>
      </div>
    </div>
    <div class="carousel-indicators">
      <span v-for="(slide, index) in slides" :key="index" :class="{ active: index === slideStoreIndex }"
        @click="goToSlide(index)"></span>
    </div>
  </div>
</template>


<script>

import { computed } from 'vue';
import { useSlideStore } from '../../store/slideStore';
import translationsData from '@/assets/text_lang/translations.json';
import { useLanguageStore } from '@/theme';

export default {
  data() {
    return {
      slides: [
        { image: require('@/assets/images/home/Home-Page-1.png'), alt: 'image 1', darkMode: false, link: '/event', index: 2 },
        { image: require('@/assets/images/home/Home-Page-2.png'), alt: 'image 2', darkMode: true, link: '/rent', index: 0 },
        { image: require('@/assets/images/home/Home-Page-3.png'), alt: 'image 3', darkMode: false, link: '/event', index: 4 },
        { image: require('@/assets/images/home/Home-Page-4.png'), alt: 'image 4', darkMode: true, link: '/event', index: 3 },
      ]
    }
  },
  setup() {
    const slideStore = useSlideStore();
    const languageStore = useLanguageStore();

    const translations = computed(() => {
      return translationsData[languageStore.currentLanguage]["home_page_1"];
    });
    if (slideStore.slideIndex > Object.keys(translations.value.title).length) {
      slideStore.setSlideIndex(0);
    }
    const slideStoreIndex = computed(() => slideStore.slideIndex);
    return {
      currentLanguage: languageStore.currentLanguage,
      translations,
      slideStoreIndex,
    };
  },
  computed: {
    isDarkMode() {
      return this.slides[this.slideStoreIndex].darkMode;
    }
  },
  methods: {
    handleTouchStart(event) {
      this.touchStartX = event.touches[0].clientX;
    },
    handleTouchMove(event) {
      this.touchEndX = event.touches[0].clientX;
    },
    handleTouchEnd() {
      const swipeThreshold = 50;
      if (this.touchStartX - this.touchEndX > swipeThreshold) {
        this.nextSlide();
      } else if (this.touchEndX - this.touchStartX > swipeThreshold) {
        this.prevSlide();
      }
      this.touchStartX = 0;
      this.touchEndX = 0;
    },

    eventRouterMenu(index) {
      const slideStore = useSlideStore();
      slideStore.setSlideIndex(index);
    },
    nextSlide() {
      const slideStore = useSlideStore();
      if (this.slideStoreIndex == this.slides.length - 1) {
        slideStore.setSlideIndex(0);
        this.$router.push('/about');
      } else {
        slideStore.setSlideIndex((this.slideStoreIndex + 1) % this.slides.length);
      }
    },
    prevSlide() {
      const slideStore = useSlideStore();
      slideStore.setSlideIndex((this.slideStoreIndex - 1 + this.slides.length) % this.slides.length);
    },
    goToSlide(index) {
      const slideStore = useSlideStore();
      slideStore.setSlideIndex(index);
    },
    handleScroll(event) {
      const currentTime = new Date().getTime();
      if (currentTime - this.lastScroll < 500) {
        console.log('Scroll too fast');
        return;
      }
      this.lastScroll = currentTime;
      if (event.deltaY > 0) {
        this.nextSlide();
      } else {

        this.prevSlide();
      }
    }
  }
}
</script>

<style scoped>

.overlay {
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dark-mode .overlay {
  background: rgba(255, 255, 255, 0.1);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}



.carousel {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  letter-spacing: 5px;
}

.carousel-inner {
  display: flex;
  transition: transform 0.5s ease;
}

.carousel-item {
  min-width: 100%;
  height: 100vh;
  position: relative;
}

.carousel-item img {
  margin-top: 100px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.caption {
  font-size: 30pt;
  line-height: 40pt;
  position: absolute;
  top: 60%;
  left: 70%;
  transform: translate(-50%, -50%);
  text-align: left;
}


.caption p {
  font-size: 30px;
  margin: 0;
  font-weight: bold;
}

.dark-mode .caption p {
  color: var(--dark-color-background);
  font-weight: bold;
}

.carousel-controls {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  pointer-events: none;
}

.carousel-control {
  background: none;
  border: none;
  font-size: 3rem;
  /* Adjust font size as needed */
  color: var(--dark-color-font);
  cursor: pointer;
  pointer-events: all;
}

.dark-mode .carousel-control {
  color: var(--dark-color-background);
}

.carousel-control.prev {
  width: 50%;
}

.carousel-control.prev button {
  display: flex;
  justify-content: flex;
  margin-left: 15px;
  width: 40px;
}

.carousel-control.next {
  display: flex;
  justify-content: flex-end;
  width: 50%;

}

.carousel-control.next button {
  margin-right: 15px;
  width: 40px;
}


.carousel-indicators {

  position: absolute;
  bottom: 30px;
  margin-bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
}


.carousel-indicators span {
  width: 20px;
  height: 20px;
  background-color: transparent;
  border: 3px solid var(--dark-color-font);
  margin: 0 5px;
  cursor: pointer;
}

.dark-mode .carousel-indicators span {
  border: 4px solid var(--dark-color-background);
}

.carousel-indicators span.active {
  background-color: var(--dark-color-font);
}

.dark-mode .carousel-indicators span.active {
  background-color: var(--dark-color-background);
}

.hero-button {
  letter-spacing: 3px;
  text-align: center;
  font-size: 30pt;
  line-height: 40pt;
  margin-top: 30px;
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--dark-color-font);
  color: var(--dark-color-background);
  padding: 10px 20px;
  text-decoration: none;
  transition: background 0.3s, color 0.3s;
  border: 2px solid transparent;
}

.dark-mode .hero-button {
  background: var(--dark-color-background);
  color: var(--dark-color-font);
}

.hero-button:hover {
  /* background: var(--dark-color-background); */
  background-color: rgba(54, 52, 52, 0.5);
  color: var(--dark-color-font);
  font-weight: bold;
  /* color: var(--dark-color-background); */
  border: 2px solid var(--dark-color-font);
}

.dark-mode .hero-button:hover {
  /* background: var(--dark-color-font); */
  background-color: rgba(255, 255, 255, 0.621);
  color: var(--dark-color-background);
  border: 2px solid var(--dark-color-background);
}

@media (max-width: 1400px) {

  .caption {
    top: 60%;
    left: 70%;
  }

  .caption p {
    font-size: 22px;
    line-height: 20pt;
  }

  .hero-button {
    font-size: 17px;
  }

  .carousel-item img {
    padding-top: 0px;
    margin-top: 80px;
  }
}

@media (max-width: 822px) {

  .carousel-controls {
    display: none;
    /* hidden */
    visibility: hidden;
  }

  .carousel-item img {
    padding-top: 0px;
    margin-top: 80px;
  }

  .caption {
    top: 70%;
    left: 50%;
  }

  .caption p {
    font-size: 18px;
    line-height: 18pt;
    /* font-weight: bold; */
    font-weight: bold;
  }

  .hero-button {
    height: 40px;
    /* width: 80%; */
    font-size: 18px;
    width: 70%;
  }

  .carousel-control {
    font-size: 1.5rem;
  }

  .carousel-control.prev button {
    margin-left: 10px;
  }


  .carousel-control.next button {
    margin-right: 10px;
  }

  .carousel-indicators {
    bottom: 15px;
    margin-bottom: 15px;
  }
  .carousel-indicators span {
    width: 15px;
    height: 15px;
    margin: 0 3px;
  }
}



</style>