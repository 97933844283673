<template>
  <div class="product-page">
    <div v-if="Object.keys(product).length > 0" class="product-detail">
      <div v-if="isMobile">
        <div class="product-info">
          <div class="header-row">
            <p>{{ product.name }}</p>
          </div>
          <p class="desc">{{ formatDescription() }}</p>
        </div>
        <div class="product-image">
          <img
            :src="'https://products-rent-cocktailservice.s3.eu-central-1.amazonaws.com/images/' + product.id + '_' + product.meta.images[0] + '.png'"
            alt="Product Image" />
        </div>
        <div class="product-options">
          <div class="column left-column">
            <p v-if="product.rental_price === '<price_individually>'" class="price">{{ translations.price_individually
              }}
            </p>
            <div v-else>
              <p class="price">{{ formatRentalPrice(totalPrice) }}</p>
              <p>{{ translations.price_suffix }}</p>
            </div>
          </div>
          <div class="vertical-line"></div>
          <div class="column right-column">
            <div class="options">
              <div class="option">
                <label for="quantity">{{ translations.add_cart.quantity }} </label>
                <input id="quantity" type="number" v-model="productQuantity" min="1" max="25" />
              </div>
              <div class="option">
                <label for="days">{{ translations.add_cart.days }}</label>
                <input id="days" type="number" v-model="daysQuantity" min="1" />
              </div>
            </div>
            <button class="product-button" @click="handleAddToCart(product)">{{ translations.add_cart.button }}</button>
          </div>
        </div>

      </div>

      <div v-if="!isMobile" class="product-image">
        <img
          :src="'https://products-rent-cocktailservice.s3.eu-central-1.amazonaws.com/images/' + product.id + '_' + product.meta.images[0] + '.png'"
          alt="Product Image" />
      </div>
      <div v-if="!isMobile" class="block">
        <div class="product-info">
          <div class="header-row">
            <p>{{ product.name }}</p>
          </div>
          <p class="desc">{{ formatDescription() }}</p>
        </div>
        <div class="product-options">
          <div class="column left-column">
            <p v-if="product.rental_price === '<price_individually>'" class="price">{{ translations.price_individually
              }}
            </p>
            <div v-else>
              <p class="price">{{ formatRentalPrice(totalPrice) }}</p>
              <p>{{ translations.price_suffix }}</p>
            </div>
          </div>
          <div class="vertical-line"></div>
          <div class="column right-column">
            <div class="options">
              <div class="option">
                <label for="quantity">{{ translations.add_cart.quantity }} </label>
                <input id="quantity" type="number" v-model="productQuantity" min="1" max="25" />
              </div>
              <div class="option">
                <label for="days">{{ translations.add_cart.days }}</label>
                <input id="days" type="number" v-model="daysQuantity" min="1" />
              </div>
            </div>
            <button class="product-button" @click="handleAddToCart(product)">{{ translations.add_cart.button }}</button>
          </div>
        </div>
      </div>
    </div>
    <div class="tags"  v-if="Object.keys(product).length > 0">
      <p>{{  product.meta.tags}}</p>
    </div>
    <CartNotification v-if="showNotification" message="Produkt dodany do koszyka!" />
    <RelatedProducts />
  </div>
  <AppFooter />
</template>

<script>
import RelatedProducts from './RelatedProducts.vue';
import CartNotification from '@/components/CartNotification.vue';


import { useCartStore } from '../../store/cart';
import { computed } from 'vue';
import { ref } from 'vue';
import AppFooter from './FootBar.vue';
import translationsData from '@/assets/text_lang/translations.json';
import { useLanguageStore } from '@/theme';

export default {
  props: {
    id: {
      type: String,
      required: true
    }
  },
  watch: {
    id(newId) {
      // Wywołaj funkcję pobierania danych dla nowego ID
      this.fetchProductDetails(newId);
    }
  },
  components: {
    RelatedProducts,
    AppFooter,
    CartNotification
  },
  data() {
    return {
      relatedProducts: [],
      currentIndex: 0,
      tags: ['Granitor', 'Carpigiani', '2 komory', '12l', '230V', '3 tryby pracy'],
      visibleCount: 3,


    };
  },
  setup() {
    const languageStore = useLanguageStore();
    const cartStore = useCartStore();
    const showNotification = ref(false);

    const productQuantity = ref(1);
    const daysQuantity = ref(1);

    const product = ref({});

    const isMobile = ref(window.innerWidth <= 822);

    const translations = computed(() => {
      return translationsData[languageStore.currentLanguage]["rent"];
    });

    const totalPrice = computed(() => {
      if (!productQuantity.value || !daysQuantity.value) return 0;
      const pricePerDay = product.value.rental_price && product.value.rental_price !== "<price_individually>"
        ? parseFloat(product.value.rental_price)
        : 0;
      return pricePerDay * productQuantity.value * daysQuantity.value;
    });


    function handleAddToCart(product) {
      const productToAdd = {
        ...product,
        quantity: productQuantity.value,
        days: daysQuantity.value
      };

      cartStore.addToCart(productToAdd);
      showNotification.value = true;

      productQuantity.value = 1;
      daysQuantity.value = 1;
      // Ukryj komunikat po 3 sekundach
      setTimeout(() => {
        showNotification.value = false;
      }, 3000);
    }
    return {
      handleAddToCart,
      product,
      currentLanguage: languageStore.currentLanguage,
      translations,
      showNotification,
      productQuantity,
      daysQuantity,
      isMobile,
      totalPrice,
    };
  },
  computed: {
    visibleProducts() {
      // Zwracamy trzy widoczne produkty w zależności od indeksu
      return this.relatedProducts.slice(this.currentIndex, this.currentIndex + this.visibleCount);
    }
  },
  methods: {
    handleResize() {
      const width = window.innerWidth;
      if (width < 780) {
        this.visibleCount = 1;
        this.currentIndex = 0;
      } else if (width < 1200) {
        this.visibleCount = 2;
        this.currentIndex = 0;
      } else {
        this.visibleCount = 3;
        this.currentIndex = 0;
      }
    },
    formatRentalPrice(rental_price) {
      const currency = this.translations.currency;
      return `${rental_price} ${currency}`;
    },
    formatDescription() {
      if (!this.product || !this.product.meta) {
        return {};
      }
      const desc = {
        [this.translations.product_detail.volume]: this.product.meta.volume || this.translations.product_detail.no_information,
        [this.translations.product_detail.diamaeter]: this.product.meta.diamaeter || this.translations.product_detail.no_information,
        [this.translations.product_detail.height]: this.product.meta.height || this.translations.product_detail.no_information,
        [this.translations.product_detail.width]: this.product.meta.width || this.translations.product_detail.no_information,
        [this.translations.product_detail.depth]: this.product.meta.depth || this.translations.product_detail.no_information,
        [this.translations.product_detail.material]: this.product.meta.material || this.translations.product_detail.no_information,
        [this.translations.product_detail.loss_price]: this.product.loss_price || this.translations.product_detail.no_information,
      };

      return Object.entries(desc)
        .map(([key, value]) => `${key}: ${value}`)
        .join(", "); // Oddzielone przecinkami, aby były w jednej linii

    },
    nextSlide() {
      // Jeśli nie jesteśmy na końcu listy, przesuwamy o jeden produkt w prawo
      if (this.currentIndex < this.relatedProducts.length - this.visibleCount) {
        this.currentIndex++;
      }
    },
    prevSlide() {
      // Jeśli nie jesteśmy na początku listy, przesuwamy o jeden produkt w lewo
      if (this.currentIndex > 0) {
        this.currentIndex--;
      }
    },
    checkIsMobile() {
      this.isMobile = window.innerWidth <= 822;
    },
    async fetchProductDetails(productId) {
      try {
        // Przykładowe wywołanie API, które pobiera produkt na podstawie ID
        const response = await fetch(`https://l4887ivvna.execute-api.eu-central-1.amazonaws.com/dev/products?id=${productId}`);
        const data = await response.json();
        this.product = data.products[0];

        const response_related_products = await fetch(`https://l4887ivvna.execute-api.eu-central-1.amazonaws.com/dev/products?category=${this.product.category}`);
        const data_related_products = await response_related_products.json();
        this.relatedProducts = data_related_products.products;

        window.scrollTo({
          top: 0,
          behavior: 'smooth', // Opcjonalne - animowane przewijanie
        });

      } catch (error) {
        console.error("Wystąpił błąd podczas pobierania danych produktu:", error);
      }
    },
  },
  mounted() {
    window.addEventListener('resize', this.checkIsMobile);
    this.checkIsMobile();
    window.addEventListener('resize', this.handleResize);
    this.handleResize(); // Ustaw liczbę widocznych produktów przy pierwszym uruchomieniu
    this.fetchProductDetails(this.id);
  },
  beforeUnmount() {  // Zmienione z beforeDestroy
    window.removeEventListener('resize', this.checkIsMobile);
    document.removeEventListener('click', this.handleClickOutside);
  }
}
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body,
html {
  height: 100%;
  overflow-x: hidden;
}

.product-page {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding-top: 290px;
  box-sizing: border-box;
  background-color: var(--dark-color-font);
  color: var(--dark-color-background);
  width: 100%;
  min-height: calc(100vh - 70px);
  /* Zapewnia, że .shop ma co najmniej pełną wysokość okna minus wysokość stopki */
  padding-left: 30px;
  padding-right: 30px;
  /* Upewnij się, że nie zakryje zawartości */
}

.product-detail {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
  width: 90%;
  max-width: 1700px;
}

.desc {
  margin: 40px 0;
  font-size: 11px;
  font-weight: bold;
}


.product-image img {
  width: 400px;
  height: auto;
  margin-right: 20px;
}

.price {
  white-space: pre-line;
  font-weight: bold;
  text-align: left;
  font-size: 15pt;
  letter-spacing: 4px;
}

.option {
  display: flex;
  justify-content: space-between;
  /* Umieszcza nagłówek po lewej, a cenę po prawej */
  align-items: center;
}

.option label {
  width: 50%;
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
  font-size: 12px;
}

.option input {
  background-color: var(--dark-color-font);
  border: 2px solid var(--dark-color-background);
  text-align: center;
  width: 50%;
  padding: 10px;
  margin-bottom: 5px;
}

.slider-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.arrow {
  background: transparent;
  border: 2px solid transparent;
  font-size: 2rem;
  cursor: pointer;
  pointer-events: all;
  color: var(--dark-color-background);
  transition: color 0.3s;
  height: 80%;
  width: 50px;
}

.arrow:hover {
  color: var(--dark-color-font);
  background-color: var(--dark-color-background);
  opacity: 0.5;
}

.left-arrow {
  position: absolute;
  left: -0px;
}

.right-arrow {
  position: absolute;
  right: -0px;
}


.products-list {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  gap: 20px;
  overflow-x: auto;
}



.prod-desc {
  margin: 10px 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* Ustawia elementy na górze */
  align-items: flex-start;
  /* Ustawia elementy od lewej strony */
}

.prod-price {
  margin: 30px 0;
  font-size: 11px;
  text-align: left;
  white-space: pre-line;
}

.right-column {
  border-left: 2px solid var(--dark-color-background);
  padding-left: 30px;
  width: 50%;
}


.block {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  margin: 20px 0px;
  width: 100%;
  border-left: 2px solid var(--dark-color-background);
  padding-left: 80px;
}

.product-info {
  width: 100%;
}

.header-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-row p {
  margin: 0;
  flex: 1;
  font-size: 16pt;
}

.product-options {
  width: 100%;
  height: 70%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.left-column {
  color: var(--dark-color-red);
}

.column {
  /* Upewnienie się, że marginesy są wyłączone */
  /* flex: 1; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* Wymuszanie zawartości na początku */
}

.options .option {
  margin-bottom: 10px;
  /* Dodajemy margines pomiędzy opcjami */
}

.tags {
  border-bottom: 2px solid var(--dark-color-background);
  border-top: 2px solid var(--dark-color-background);
  padding: 20px 20px;
  width: 90%;
  display: block;
}

.tags p {
  font-size: 11px;
}


.product-button {
  text-align: center;
  background-color: var(--dark-color-background);
  color: var(--dark-color-font);
  padding: 5px 10px;
  letter-spacing: 2px;
  border: 1px solid transparent;
  transition: background 0.3s, color 0.3s;
  border: 2px solid transparent;
  text-decoration: none;
  text-transform: none;
  /* To nadpisuje ustawienie dużych liter */
  text-transform: uppercase;
}

.product-button:hover {
  background-color: var(--dark-color-font);
  color: var(--dark-color-background);
  border: 2px solid var(--dark-color-background);
}

.product-item {
  background-color: transparent;
  padding: 10px;
  margin: 0 20px;
  text-align: center;
  border: 1px solid transparent;
  min-width: 200px;
  display: flex;
  justify-content: center;
}


.product-item img {
  width: 120px;
  height: 120px;
  margin-bottom: 10px;
}


@media (min-width: 823px) and (max-width: 1380px) {
  .right-column {
    width: 70%;
  }

  .product-image img {
    width: 300px;
    margin-top: 50px;
    height: auto;
  }

  .product-item img {
    width: auto;
    height: 120px;
  }

  .prod-price {
    margin: 20px 0;
  }

  .prod-desc {
    margin: 10px 10px;
  }

  .product-button {
    font-size: 11px;
  }

  .left-arrow {
    position: absolute;
    left: -20px;
  }

  .right-arrow {
    position: absolute;
    right: -20px;
  }

  .product-page {
    padding-bottom: 300px;
  }
}



@media (max-width: 822px) {

  .product-page {

    width: 100%;
    padding: 10px;
    padding-top: 130px;
  }

  .product-info {
    padding: 0px;
    width: 100%;
  }

  .header-row p {
    font-size: 15px;
    line-height: 16pt;
  }

  .product-options {
    padding: 0px;
    margin-top: 40px;
    width: 100%;
    flex-direction: column;
    /* Ustawia elementy jedno pod drugim */
    justify-content: center;
    /* Opcjonalne - centruje elementy pionowo */
    align-items: stretch;
    /* Rozciąga elementy na pełną szerokość */
    height: auto;
    /* Automatyczna wysokość, dopasowana do zawartości */
  }

  .desc {
    margin: 10px 0;
    font-size: 10px;
    font-weight: normal;
  }


  .block {
    padding-left: 30px;
    flex-direction: row;
  }

  .right-column {
    width: 100%;
    border-left: none;
    padding-left: 0;
  }

  .product-image {
    display: flex;
    /* Dodaj flexbox */
    justify-content: center;
    /* Wyśrodkowuje obraz poziomo */
    align-items: center;
    /* Wyśrodkowuje obraz pionowo (opcjonalne, jeśli wymagane) */
    width: 100%;
  }

  .product-image img {
    width: 200px;
    height: auto;
    margin: 0;
    /* Usuń jakiekolwiek marginesy */
  }

  .left-column {
    margin-bottom: 20px;
    font-size: 12px;
  }

  .price {
    white-space: pre-line;
    font-weight: bold;
    text-align: left;
    font-size: 16px;
    letter-spacing: 4px;
  }

  .option label {
    font-size: 12px;
  }

  .option input {
    width: 50%;
    height: 30px;
    padding: 5px;
  }

  .prod-price {
    margin: 10px 0;
    font-size: 10px;
  }

  .prod-desc {
    margin: 0px 10px;
  }

  .product-button {
    font-size: 10px;
  }

}
</style>
