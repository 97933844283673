import { defineStore } from 'pinia';

export const useCartStore = defineStore('cart', {
  state: () => ({
    items: JSON.parse(localStorage.getItem('cartItems')) || [],
    add_information: {
      deliveryDate: '',
      deliveryTime: '',
      pickupDate: '',
      pickupTime: '',
      message: ''
    }
  }),
  actions: {
    addToCart(product) {
      const existingProduct = this.items.find(item => item.id === product.id);
      if (existingProduct) {
        existingProduct.quantity += product.quantity;
        existingProduct.days = product.days;
      } else {
        this.items.push({ ...product, quantity: product.quantity || 1, days: product.days || 1 });
      }
      this.saveCart();
    },
    updateAddInformation(newInfo) {
      this.add_information = { ...this.add_information, ...newInfo };
      this.saveCart();
    },
    removeFromCart(productId) {
      this.items = this.items.filter(item => item.id !== productId);
      this.saveCart();
    },
    clearCart() {
      this.items = [];
      this.add_information = {
        deliveryDate: '',
        deliveryTime: '',
        pickupDate: '',
        pickupTime: '',
        message: ''
      };
      this.saveCart();
    },
    saveCart() {
      localStorage.setItem('cartItems', JSON.stringify(this.items));
      localStorage.setItem('cartAddInfo', JSON.stringify(this.add_information));
    },
    loadCart() {
      const savedItems = localStorage.getItem('cartItems');
      if (savedItems) {
        this.items = JSON.parse(savedItems);
      }
      const savedAddInfo = localStorage.getItem('cartAddInfo');
      if (savedAddInfo) {
        this.add_information = JSON.parse(savedAddInfo);
      }
    }
  },
  getters: {
    totalNet() {
      // Oblicza sumę netto dla wszystkich pozycji, tylko dla tych z liczbową ceną, ilością i dniami
      try {
        const total = this.items.reduce((total, item) => {
          const price = parseFloat(item.rental_price);
          const quantity = parseFloat(item.quantity);
          const days = parseFloat(item.days);

          // Sprawdzamy, czy cena, ilość oraz dni są liczbami; jeśli nie, ignorujemy tę pozycję
          if (isNaN(price) || isNaN(quantity) || isNaN(days)) {
            return total;
          }

          return total + price * quantity * days;
        }, 0);

        // Zaokrąglenie do dwóch miejsc po przecinku
        return total.toFixed(2);
      } catch (e) {
        console.error('Error calculating totalNet:', e);
        return '0.00';
      }
    },

    totalBrutto() {
      // Oblicza sumę brutto na podstawie totalNet, z dodanym VAT 23%
      try {
        const totalNet = parseFloat(this.totalNet);
        if (isNaN(totalNet) || totalNet <= 0) {
          return '0.00';
        }

        // Zaokrąglenie do dwóch miejsc po przecinku
        return (totalNet * 1.23).toFixed(2);
      } catch (e) {
        console.error('Error calculating totalBrutto:', e);
        return '0.00';
      }
    }
  }
});

