<template>
  <div class="shop">
    <div class="header">
      
    </div>
    <p class="title">{{ translations.title }}</p>
    <div v-if="isLoading">{{ translations.loading }}</div>
    <div v-else-if="error">{{ error }}</div>
    <div v-else class="document-content">
      <div v-html="renderedMarkdown"></div>
    </div>
    <div class="download-button">
      <button @click="downloadFile">{{ translations.product_button }}</button>
    </div>
  </div>
  <AppFooter />
</template>


<script>

import AppFooter from './FootBar.vue';

import { ref, onMounted, computed, watch } from 'vue';
import translationsData from '@/assets/text_lang/translations.json';
import { useLanguageStore } from '@/theme';

import MarkdownIt from 'markdown-it';


export default {
  components: {
    AppFooter
  },
  data() {
    return {
    };
  },
  setup() {
    const languageStore = useLanguageStore();
    const currentLanguage = computed(() => languageStore.currentLanguage);
    const documentContent = ref({ title: '', content: [] });
    const renderedMarkdown = ref('');
    const isLoading = ref(true);
    const error = ref(null);

    const markdown = new MarkdownIt({
      html: true, 
      linkify: true,
      typographer: true,
    });
    const translations = computed(() => {
      return translationsData[languageStore.currentLanguage]["price"];
    });

    const fetchDocument = async () => {
      try {
        const response = await fetch('https://products-rent-cocktailservice.s3.eu-central-1.amazonaws.com/docs/cennik.md');

        if (!response.ok) {
          throw new Error("Nie udało się pobrać dokumentu.");
        }

        const text = await response.text();
        documentContent.value = text;

        // Renderuj Markdown jako HTML
        renderedMarkdown.value = markdown.render(text);
      } catch (err) {
        console.error(err);
        error.value = 'Nie udało się pobrać dokumentu.';
      } finally {
        isLoading.value = false;
      }
    };
    

    onMounted(() => {
      fetchDocument();
    });


    watch(currentLanguage, () => {
      fetchDocument();
    });

    return {
      translations,
      currentLanguage,
      documentContent,
      renderedMarkdown,
      isLoading,
      error,
    };
  },
  methods: {
    async downloadFile() {
      try {
        const response = await fetch('https://products-rent-cocktailservice.s3.eu-central-1.amazonaws.com/docs/cennik.pdf');

        if (!response.ok) {
          throw new Error("Nie udało się pobrać pliku.");
        }

        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        a.download = 'cennik.pdf'; // Nazwa pliku
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
      } catch (err) {
        console.error(err);
        alert("Wystąpił problem podczas pobierania pliku.");
      }
    },
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body,
html {
  height: 100%;
  overflow-x: hidden;
  /* Wyłączenie przewijania w poziomie */
}

.shop .document-content ::v-deep table {
  border: 1px solid var(--dark-color-background);
  border-collapse: collapse;
  width: 100%;
}

.shop .document-content ::v-deep th,
.shop .document-content ::v-deep td {
  border: 1px solid var(--dark-color-background);
  padding: 8px;
}

.shop {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 250px;
  box-sizing: border-box;
  background-color: var(--dark-color-font);
  color: var(--dark-color-background);
  width: 100%;
  /* Ustawienie szerokości na 100% */
  height: 100%;
  padding-left: 30px;
  padding-right: 30px;
}

.shop .document-content {
  text-align: left;
}

.shop .document-content {
  font-family: 'MyFont', sans-serif;
  white-space: pre-wrap;
  font-size: 10px;
  margin: 30px;
  letter-spacing: 3px;
  font-weight: normal;
}

h2 {
  font-size: 13px;
}

.shop .header {
  width: 90%;
  text-align: left;
  margin: 10px;
}

.shop .header .title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
}

.shop .header span {
  font-size: 12px;
  color: var(--dark-color-background);
}

.shop .download-button button{
  margin-top: 20px;
  border-radius: 2px;
  background-color: var(--dark-color-background);
  color: var(--dark-color-font);
  padding: 10px 20px;
  font-size: 14px;
  font-weight: bold;
  width: 100%;
}



@media (min-width: 599px) and (max-width: 721px) {
  .shop {
    padding-top: 200px;
  }

  .shop .header {
    margin: 30px;
    width: 90%;
  }
  .shop .document-content 
  {
    font-size: 10px;
  }

}

@media (max-width: 598px) {
  .shop {
    padding-top: 120px;
  }

  .shop .header {
    margin: 10px;
    margin-bottom: 0px;
    width: 100%;
  }
  .shop .document-content 
  {
    font-size: 5px;
    letter-spacing: 2px;
  }

  .shop .download-button button{
    font-size: 12px;
  }

}

@media (max-width: 360px) {
  .shop .document-content 
  {
    font-size: 4px;
    letter-spacing: 2px;
  }
}

</style>
