<template>
    <div class="related-products">
        <h2>{{ translations.product_title }}</h2>
        <div class="slider-container">
            <button @click="prevSlide" class="arrow left-arrow">❮</button>
            <div class="products-list">
                <div v-for="(product) in visibleProducts" :key="product.id" class="product-item">
                    <img :src="'https://products-rent-cocktailservice.s3.eu-central-1.amazonaws.com/images/' + product.id + '_' + product.meta.images[0] + '.png'"
                        alt="image" />
                    <div class="product-details">
                        <div class="prod-info">
                            <p class="name-prod">{{ product.name }}</p>
                            <p class="price">{{ formatRentalPrice(product.rental_price) }}</p>
                        </div>
                        <router-link :to="`/rent/product/${product.id}`" class="product-button">{{
                            translations.product_button }}</router-link>
                    </div>
                </div>
            </div>
            <button @click="nextSlide" class="arrow right-arrow">❯</button>
        </div>
    </div>
</template>

<script>
import { computed } from 'vue';
import translationsData from '@/assets/text_lang/translations.json';
import { useCategoryStore, useLanguageStore } from '@/theme';
export default {
    name: 'RelatedProducts',
    data() {
        return {
            relatedProducts: [],
            currentIndex: 0,
            visibleCount: 3,
        }
    },
    setup() {
        const languageStore = useLanguageStore();
        const categoryStore = useCategoryStore();
        const translations = computed(() => {
            return translationsData[languageStore.currentLanguage]["rent"];
        });
        const currentCategory = computed(() => categoryStore.currentCategory);


        return {
            currentLanguage: languageStore.currentLanguage,
            translations,
            currentCategory
        };
    },
    computed: {
        visibleProducts() {
            // Zwracamy trzy widoczne produkty w zależności od indeksu
            return this.relatedProducts.slice(this.currentIndex, this.currentIndex + this.visibleCount);
        }
    },
    mounted() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize(); // Ustaw liczbę widocznych produktów przy pierwszym uruchomieniu
        this.fetchProductDetails();
    },
    methods: {
        handleResize() {
            const width = window.innerWidth;
            if (width < 780) {
                this.visibleCount = 1;
                this.currentIndex = 0;
            } else if (width < 1200) {
                this.visibleCount = 2;
                this.currentIndex = 0;
            } else {
                this.visibleCount = 3;
                this.currentIndex = 0;
            }
        },
        formatRentalPrice(rental_price) {
            if (rental_price === "<price_individually>") {
                return this.translations.price_individually;
            }
            const suffix = this.translations.price_suffix;
            const currency = this.translations.currency;
            return `${rental_price} ${currency} ${suffix}`;
        },
        nextSlide() {
            // Jeśli nie jesteśmy na końcu listy, przesuwamy o jeden produkt w prawo
            if (this.currentIndex < this.relatedProducts.length - this.visibleCount) {
                this.currentIndex++;
            }
        },
        prevSlide() {
            // Jeśli nie jesteśmy na początku listy, przesuwamy o jeden produkt w lewo
            if (this.currentIndex > 0) {
                this.currentIndex--;
            }
        },
        getRandomProducts(products, count) {
            const shuffled = products.sort(() => 0.5 - Math.random());
            return shuffled.slice(0, count);
        },
        async fetchProductDetails() {
            try {
                const response_related_products = await fetch(`https://l4887ivvna.execute-api.eu-central-1.amazonaws.com/dev/products`);
                const data_related_products = await response_related_products.json();

                const products = data_related_products.products;
                this.relatedProducts = this.getRandomProducts(products, 7);
            } catch (error) {
                console.error("Wystąpił błąd podczas pobierania danych produktu:", error);
            }
        },
    },

}


</script>

<style scoped>
.related-products {
    margin-top: 50px;
    width: 90%;
}

.slider-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.related-products .products-list {
    display: flex;
    justify-content: center;
    gap: 20px;
    overflow-x: auto;
    width: 90%;
}


.related-products h2 {
    margin-left: 20px;
    align-items: flex-end;
}

.related-products .arrow {
    background: transparent;
    border: 2px solid transparent;
    font-size: 2rem;
    cursor: pointer;
    pointer-events: all;
    color: var(--dark-color-background);
    transition: color 0.3s;
    height: 80%;
    width: 50px;
}

.related-products .arrow:hover {
    color: var(--dark-color-font);
    background-color: var(--dark-color-background);
    opacity: 0.5;
}

.related-products .left-arrow {
    position: absolute;
    left: -0px;
}

.related-products .right-arrow {
    position: absolute;
    right: -0px;
}

.product-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    height: auto;
    max-width: 33%;
    text-align: left;
    width: 50%;
}


.product-item img {
    width: 120px;
    height: 120px;
}

.product-details {
    margin: 20px;
    padding-left: 20px;
    border-left: 2px solid var(--dark-color-background);
}



.product-list .product-details .prod-info {
    height: 100px;
    margin-bottom: 20px;
}

.prod-info .name-prod {
    font-size: 12px;
    line-height: 14pt;
    display: flex;
    align-items: center;
    text-align: left;
    margin-bottom: 40px;
    width: 100%;
    height: 50px;
    /* Stała wysokość dla wyrównania */
    overflow: hidden;
    /* Ukrycie nadmiaru tekstu */
    text-overflow: ellipsis;
    /* Zastąpienie nadmiaru tekstu wielokropkiem */
}



.price {
    font-size: 10px;
    white-space: pre-line;
    color: var(--dark-color-red);
}


.prod-info .price {
    white-space: pre-line;
    display: flex;
    align-items: center;
    /* Wyrównanie tekstu do góry */
    text-align: left;
    font-size: 12px;
    line-height: 14pt;
}


.product-button {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: var(--dark-color-background);
    color: var(--dark-color-font);
    letter-spacing: 2px;
    font-size: 12px;
    border: 1px solid transparent;
    transition: background 0.3s, color 0.3s;
    text-decoration: none;
    width: 100px;
    height: 30px;
}

.product-button:hover {
    background-color: var(--dark-color-font);
    color: var(--dark-color-background);
    border: 1px solid var(--dark-color-background);
}

.left-arrow {
    position: absolute;
    left: -0px;
}

.right-arrow {
    position: absolute;
    right: -0px;
}

@media (max-width: 822px) {

    .related-products {
        margin-top: 20px;
        width: 100%;
        padding: 10px;
    }

    .related-products h2 {
        font-size: 18px;
    }

    .related-products .arrow {
        font-size: 1.5rem;
        width: 40px;
    }

    .product-item {
        padding: 10px;
        width: 80%;
        max-width: 90%;
    }
    .product-item img {
        width: 70px;
        height: 70px;
    }

    .prod-info .name-prod {
        font-size: 10px;
        line-height: 12pt;
        margin-bottom: 10px;
    }
    .prod-info .price {
        font-size: 10px;
        line-height: 12pt;
    }

    .product-button {
        font-size: 10px;
        width: 80px;
        height: 25px;
    }
}
</style>