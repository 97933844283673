<template>
    <div class="related-products">
        <p class="trust">{{ translations }}</p>
        <div class="slider-container">
            <button @click="prevSlide" class="arrow left-arrow">❮</button>
            <div class="products-list">
                <div v-for="(product) in visibleProducts" :key="product.id" class="product-item">
                    <img :src="product.image" :alt="product.alt" />
                </div>
            </div>
            <button @click="nextSlide" class="arrow right-arrow">❯</button>
        </div>
    </div>
</template>

<script>
import { computed } from 'vue';
import { useCategoryStore, useLanguageStore } from '@/theme';
export default {
    name: 'RelatedProducts',
    data() {
        return {
            relatedProducts: [
                { image: require('@/assets/images/portfolio/logo/Angels-Envy.png'), alt: 'Angels Envy', id: 1 },
                { image: require('@/assets/images/portfolio/logo/Aberfeldy.png'), alt: 'Aberfeldy', id: 2 },
                { image: require('@/assets/images/portfolio/logo/Auchentoshan.png'), alt: 'Auchentoshan', id: 3 },
                { image: require('@/assets/images/portfolio/logo/Bombay-Sapphire.png'), alt: 'Bombay Sapphire', id: 4 },
                { image: require('@/assets/images/portfolio/logo/Dewars.png'), alt: 'Dewars', id: 5 },
                { image: require('@/assets/images/portfolio/logo/Glenfiddich_1.png'), alt: 'Glenfiddich', id: 6 },
                { image: require('@/assets/images/portfolio/logo/Jim-Beam.png'), alt: 'Jim Beam', id: 7 },
                { image: require('@/assets/images/portfolio/logo/Makers-Mark.png'), alt: 'Makers Mark', id: 8 },
                { image: require('@/assets/images/portfolio/logo/Roku-Gin.png'), alt: 'Roku Gin', id: 9 },
            ],
            currentIndex: 0,
            visibleCount: 3,
            slideInterval: null, // Zmienna do przechowywania interwału
        }
    },
    setup() {
        const languageStore = useLanguageStore();
        const categoryStore = useCategoryStore();
        const currentCategory = computed(() => categoryStore.currentCategory);

        const translations = computed(() => {
            if (languageStore.currentLanguage == 'en') {
                return "our clients";
            } else {
                return "Zaufali nam";
            }
        });

        return {
            currentLanguage: languageStore.currentLanguage,
            currentCategory,
            translations
        };
    },
    computed: {
        visibleProducts() {
            return this.relatedProducts.slice(this.currentIndex, this.currentIndex + this.visibleCount);
        }
    },
    mounted() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
        this.startAutoSlide(); // Uruchamiamy automatyczne przesuwanie slajdów
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.handleResize);
        this.stopAutoSlide(); // Wyłączamy interwał przy odmontowaniu
    },
    methods: {
        handleResize() {
            const width = window.innerWidth;
            if (width < 780) {
                this.visibleCount = 1;
                this.currentIndex = 0;
            } else if (width < 1200) {
                this.visibleCount = 2;
                this.currentIndex = 0;
            } else {
                this.visibleCount = 3;
                this.currentIndex = 0;
            }
        },
        nextSlide() {
            if (this.currentIndex < this.relatedProducts.length - this.visibleCount) {
                this.currentIndex++;
            } else {
                this.currentIndex = 0; // Jeśli jesteśmy na końcu, wracamy na początek
            }
        },
        prevSlide() {
            if (this.currentIndex > 0) {
                this.currentIndex--;
            } else {
                this.currentIndex = this.relatedProducts.length - this.visibleCount; // Jeśli jesteśmy na początku, idziemy na koniec
            }
        },
        startAutoSlide() {
            // Uruchamiamy interwał, który przesuwa slajdy co 3 sekundy
            this.slideInterval = setInterval(() => {
                this.nextSlide();
            }, 4000);
        },
        stopAutoSlide() {
            // Usuwamy interwał
            if (this.slideInterval) {
                clearInterval(this.slideInterval);
                this.slideInterval = null;
            }
        }
    }
}
</script>


<style scoped>
.related-products {
    margin-top: 10px;
    width: 90%;
}

.slider-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.related-products .products-list {
    display: flex;
    justify-content: center;
    gap: 20px;
    overflow-x: auto;
    width: 90%;
}


.related-products h2 {
    margin-left: 20px;
    align-items: flex-end;
}

.related-products p {
    font-size: 16px;
    text-align: center;
}

.related-products .arrow {
    background: transparent;
    border: 2px solid transparent;
    font-size: 3rem;
    cursor: pointer;
    pointer-events: all;
    color: var(--dark-color-font);
    transition: color 0.3s;
    height: 100%;
    width: 50px;
}

.related-products .arrow:hover {
    color: var(--dark-color-font);
    background-color: var(--dark-color-background);
    opacity: 0.5;
}

.related-products .left-arrow {
    position: absolute;
    left: -0px;
}

.related-products .right-arrow {
    position: absolute;
    right: -0px;
}

.product-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    height: auto;
    max-width: 33%;
    text-align: left;
    width: 50%;
}


.product-item img {
    width: auto;
    height: 200px;
    object-fit: contain;
    /* Dopasowanie obrazu wewnątrz obszaru */
    aspect-ratio: 1 / 1;
    /* Ustawienie proporcji obrazu */
    display: block;
    margin: auto;
    /* Wycentrowanie obrazu */
}

.product-details {
    margin: 20px;
    padding-left: 20px;
    border-left: 2px solid var(--dark-color-background);
}



.product-list .product-details .prod-info {
    height: 100px;
    margin-bottom: 20px;
}

.prod-info .name-prod {
    font-size: 12px;
    line-height: 14pt;
    display: flex;
    align-items: center;
    text-align: left;
    margin-bottom: 40px;
    width: 100%;
    height: 50px;
    /* Stała wysokość dla wyrównania */
    overflow: hidden;
    /* Ukrycie nadmiaru tekstu */
    text-overflow: ellipsis;
    /* Zastąpienie nadmiaru tekstu wielokropkiem */
}



.left-arrow {
    position: absolute;
    left: -0px;
}

.right-arrow {
    position: absolute;
    right: -0px;
}

@media (max-height: 1000px) {

    .related-products p {
        font-size: 10px;
    }

    .product-item {
        width: 30%;
    }
    .product-item img {
        max-width: 80%;
        max-height: 70%;
    }

}

@media (max-width: 822px) {

    .related-products p {
        font-size: 10px;
        margin: 0;
    }

    .related-products {
        margin-top: 0px;
        width: 100%;
        padding: 10px;
    }

    .related-products h2 {
        font-size: 18px;
    }

    .related-products .arrow {
        font-size: 1.5rem;
        width: 40px;
    }

    .product-item {
        width: auto;
        height: 200px;
    }

    .product-item img {
        max-width: 80%;
        max-height: 70%;
    }

}
</style>