<template>
  <div>
    <!-- Główna nawigacja wyświetlana, gdy stopka nie jest wyświetlana -->
    <NavBar v-if="notShouldShowNavBar" />
    
    <!-- Nawigacja sklepu wyświetlana, gdy stopka jest wyświetlana -->
    <NavBarShop v-if="shouldShowNavBarShop" />

    <div class="content">
      <router-view v-slot="{ Component }">
        <component :is="Component" />
      </router-view>
    </div>

    <!-- Stopka wyświetlana tylko na wybranych stronach -->
    <!-- <AppFooter v-if="shouldShowFooter" /> -->
  </div>
</template>

<script>
import NavBar from './components/NavBar.vue';
import NavBarShop from './components/NavBarShop.vue';
import AppFooter from './components/FootBar.vue';

export default {
  components: {
    NavBar,
    NavBarShop,
    AppFooter
  },
  computed: {
    shouldShowFooter() {
      return this.$route.path.startsWith('/rent') || this.$route.path === '/price' || this.$route.path === '/contact' || this.$route.path.startsWith('/cart');
    },
    shouldShowNavBarShop() {
      return this.$route.path.startsWith('/rent') || this.$route.path === '/price' || this.$route.path.startsWith('/cart');
    },
    notShouldShowNavBar() {
      return !this.shouldShowNavBarShop;
    }
  }
};
</script>

<style>
body {
  margin: 0;
  transition: background-color 0.3s, color 0.3s;
}

.content {
  flex: 1;
}
</style>